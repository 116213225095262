export const environment = {
    production: true,
    authConfig: {
      tenant: 'chevron.onmicrosoft.com',
      clientId: '2c28f08f-faff-479d-b26e-cdd9d0c57479',
      endpoints: {
        graphApiUri: "https://graph.microsoft.com",
      },

      cacheLocation: 'localStorage', 
      expireOffsetSeconds:3550,
      navigateToLoginRequestUrl:false,
    },
    webApiUrl: 'https://mba-api-dev.azure.chevron.com/',
    cacheLocation: 'localStorage', 
    //redirectUri:window.location.origin+'/#/search-material',
    //expireOffsetSeconds:3550,
    navigateToLoginRequestUrl:false,
  };
  